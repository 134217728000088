#top-overlay {
    position: fixed;
    top: 0px;

    width: 100vw;
    height: $MenuHeightDesktop / 2;

    z-index: 10;

    background: rgba(251, 251, 248, 1.0);
    background-image: $gradient2;
    box-shadow: $boxShadow;

    @media screen and (min-width: #{$iPad}) {
        height: 87.5px;
    }
    @media screen and (min-width: #{$iPadPro}) {
        height: $MenuHeightDesktop;
    }


    #overlay-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        height: 100%;

        padding: 0px 10px;

        @import 'logo';
        @import 'menu';
    }
}