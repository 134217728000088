#menu-icon {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;

    #bar1,
    #bar2,
    #bar3 {
        width: 32px;
        height: 5px;
        background-color: black;
        transition-duration: .3s;
        transition-timing-function: ease-out;
    }

    #bar1 {
        margin: 5px 0px 0px;
    }

    #bar2 {
        margin: 5px 0px;
    }

    #bar3 {
        margin: 0px 0px 5px;
    }

    &.open {

        #bar1,
        #bar3 {
            margin: 0px;
            padding: 0px;
            height: 0px;
            opacity: 0;
        }

        #bar2 {
            box-sizing: border-box;
            border-top: 5px solid black;
            border-left: 5px solid black;
            width: 24px;
            height: 24px;
            background-color: transparent;
            transform: rotate(45deg) translate(3px, 3px);
        }

    }
}