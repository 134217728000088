/* ------------------------------------------------ COLOR SCHEME ------------------------------------------------------------- */

// Brand Colors
$CoEvoBlue: rgb(0, 31, 255); 
$CoEvoCharcoal: #696969;
$CoEvoGreydark: rgb(184, 185, 187);
$CoEvoGreymedium: rgb(203, 204, 206);
$CoEvoGreylight: rgb(220, 221, 222);
$CoEvoWhite-o: rgb(255, 251, 247);

$TerpRed: rgb(207, 16, 45);
$MDYellow: #EAAB00;
$LightSkyBlue: #bdc9d5;


// --------------------------------------- GRADIENTS

// Horizontal Linear Gradients
$Greylight-Greydark: linear-gradient(to bottom, $CoEvoGreylight, $CoEvoGreydark);
$MDY-O-MDY: linear-gradient(to bottom, $MDYellow, Orange, $MDYellow);
$MDY-TerpR-Black: linear-gradient(to bottom, $MDYellow, $TerpRed, Black);
$gradient: linear-gradient(to bottom, rgba(251, 251, 248, 1.0),rgba(46, 37, 37, 0.05));
$gradient2: linear-gradient(to bottom, rgba(251, 251, 248, 1.0),rgba(46, 37, 37, 0.1));
$gradientBlack: linear-gradient(to top, black, #1F1F1F);
$gradientReverse: linear-gradient(to top, $CoEvoWhite-o, #D0D0D0);

// Vertical Linear Gradients
$vert-coevW-W-coevW: linear-gradient(to right, $CoEvoWhite-o, white, $CoEvoWhite-o);


// Radial Gradients
$Greylig-rad-med: radial-gradient($CoEvoGreylight, $CoEvoGreymedium);
$Greydar-rad-char-black: radial-gradient($CoEvoGreydark, $CoEvoCharcoal, rgb(14, 14, 14));
$Rad-W-W-MDY: radial-gradient(white, white, $MDYellow);


// --------------------------------------- SHADOWS

$Surroundshadow: 0px 0px 4px rgba(103, 103, 104, .5);
$Topshadow: 0px -1px 4px rgba(103, 103, 104, .5);
.Topshadow { box-shadow: 0px -.5px 4px rgba(103, 103, 104, .5);}


// Top Overlay
$MenuHeightDesktop: 175px;
$logoSize: 195px; 
$logoMobile: 87.5px;

//widths
$iPhone5: 320px;
$iPhone6-7-8: 414px; 
$iPad: 768px;
$iPadPro: 1024px;
$mobileMenuIcon: 570px;


// Heights
$FullMobile: 498px;
$MinDesktop: 480px;
$largeMobileMenuNavBar: 157.5px;
$largeDesktopMenuNavBar: 245px;
$smallMenuNavBar: 245px;


// For short view heights, pairs with overiPad
// e.g. when you rotate a mobile device
$landscapeBP: 480px;


// Footer 
$footerHeight: 120px;


// Borders
$borderWidthLogo: 4px;
$borderStyle: $borderWidthLogo solid $CoEvoGreylight;

$borderRadius: 30px;


// Box Shadows
$boxShadow: 1px 2px 4px 0px rgba(0, 0, 0, .2),
0px 2px 4px 0px rgba(0, 0, 0, .2),
-1px 2px 4px 0px rgba(0, 0, 0, .2);


// Circle Shadows
$circleShadow: 0px -1px 4px 0px rgba(0, 0, 0, .2),
1px 0px 4px 0px rgba(0, 0, 0, .2),
0px 1px 4px 0px rgba(0, 0, 0, .2),
-1px 0px 4px 0px rgba(0, 0, 0, .2);

//Text Shadows
$textShadow: -1px 2px 4px rgba(0, 0, 0, .3),
0px 1px 4px rgba(0, 0, 0, .3),
1px 2px 4px rgba(0, 0, 0, .3);


// STANDARDS
// * = applies to all elements
// Ensures all elements are sized to include padding and border in total width and height
* {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif; 
  }

a {
    &:hover {
        cursor: pointer;
    }
}

h1, h2, h3, h4 {
    text-align: center;
    margin: 0px;
    font-size: 1em;
    
}

h1 {
    font-size: 1.20em;
    margin-bottom: 10px;

    @media screen and (min-width:$iPad) {
        font-size: 1.8em;
        margin-bottom: 25px;
    }
    @media screen and (min-width:$iPadPro) {
        margin-bottom: 30px;
    }
    @media screen and (max-width:$iPhone5) {
        font-size: 1.0em;
    }
}

h2 {
    font-size: 1.10em;
    margin-bottom: 28px;

    @media screen and (min-width:$iPad) {
        font-size: 1.6em;
    }
    @media screen and (min-width:$iPadPro) {
        margin-bottom: 35px;
    }
    @media screen and (max-width:$iPhone5) {
        font-size: 0.9em;
    }
}

h3{
    font-size:1.0em;
    margin-bottom: 8px;

    @media screen and (min-width:$iPad) {
        font-size: 1.3em;
    }
    @media screen and (min-width:$iPadPro) {
        margin-bottom: 30px;
    }
    @media screen and (max-width:$iPhone5) {
        font-size: 0.8em;
    }
}

h4 {
    font-size: 0.9em;
    margin-bottom: 6px;

    @media screen and (min-width:$iPad) {
        font-size: 1.2em;
    }
}


p {
    color: $CoEvoWhite-o;
    font-size: .8em; 
    text-align: center;

    @media screen and (min-width: $iPad) {
        font-size: 1.0em;
    }
    @media screen and (max-width:$iPhone5) {
        font-size: 0.7em;
    }
}


html, body {
    background: $Greylight-Greydark;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100vw;
    height: auto;
    color: $CoEvoWhite-o;
    padding: 0;
    margin: 0;
    
}

#body-content {
    padding-top: 157.5px;
    height: auto;
    min-height: calc(100vh - #{$footerHeight});

    @media screen and (min-width:$iPadPro) {
        padding-top: $largeDesktopMenuNavBar;
    }
    
}

a:hover {
    background: $MDYellow;
    border-color: $TerpRed;
    opacity: 0.85;
    transition: 0.5s;
}

a.contact-button {
    display:inline-block;
    margin:0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration:none;
    text-transform:uppercase;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    text-align:center;
    transition: all 0.15s;

}
a.contact-button:hover{
    color:rgb(255, 251, 247);
    border-color: $TerpRed;
    background-color: $MDYellow;
}
a.contact-button:active{
    color:#BBBBBB;
    border-color:#BBBBBB;
}
@media all and (max-width:30em){
 a.contact-button{
    display:block;
    margin:0.4em auto;
    }   
}

ul {
    list-style: none;
    padding-inline-start: 0;
}

.large-click {
    padding:2em 6em;
    border:0.50em solid black;
    border-radius: 0.50em;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (min-width:$iPad) {
        padding: 2em 9em;
    }
    @media screen and (min-width:1100px) {
        padding:3.0em 11.00em;
        font-size: 1.0em;
    }
}
.small-click {
    padding:.5em 1.5em;
    border: 0.20em solid $TerpRed;
    color: $TerpRed;

    @media screen and (min-width:$iPad) {
        padding:0.30em .50em;
        border: 0.16em solid $TerpRed;
    }
    @media screen and (min-width:1100px) {
        padding:1.0em 3.00em;
        border: 0.20em solid $TerpRed;
    }
}

.footer-click {
    padding:.25em 0.80em;
    border: 0.05em solid $CoEvoWhite-o;
    color: $CoEvoWhite-o;
    font-size: .8em;

    @media screen and (min-width:$iPad) {
        padding:0.30em .50em;
        border: 0.16em solid $CoEvoWhite-o;
    }
    @media screen and (min-width:1100px) {
        padding:1.0em 3.00em;
        border: 0.20em solid $CoEvoWhite-o;
    }
}