
.greylight-rad-med {
    background: $Greylig-rad-med;
}

.clear-rad-red {
    background: radial-gradient(rgba(207, 16, 45, 0), rgba(207, 16, 45, .25), $TerpRed);
}

.coevo-charcoal {
    background-color: $CoEvoCharcoal;
}

.light-white-o {
    background: linear-gradient(to bottom, #dfe0e0, rgb(255, 250, 245));
}

.greydar-rad-char-black {
    background: $Greydar-rad-char-black;
}

.rad-w-w-mdy { 
    background: $Rad-W-W-MDY;
}

.vert-coevw-w-coevw {
    background: $vert-coevW-W-coevW;
}