/* ------------------------------------------------ FULL SCREEN HEIGHT CONTAINERS -------------------------------------------------- */


// Full Screen Container Under Menu - Use this class when you want to fill the view height of the screen beneath the menu
.full-view-height-sub-menu-large {
    
    width: 100vw; 
    height: calc(100vh - #{$largeMobileMenuNavBar});
   
    @media screen and (min-width: $iPad) {
        

        img {
            max-height: 417px;
            max-width: 417px;
        }
    }
    @media screen and (min-width: $iPadPro) {
        height: calc(100vh - #{$largeDesktopMenuNavBar});
    }
}

.full-view-height-sub-menu-small {
    position: relative;
    width: 100vw; 
    height: calc(100vh - #{$largeMobileMenuNavBar});

    @media screen and (min-width: $iPadPro) {
        height: calc(100vh - #{$largeDesktopMenuNavBar});
    }
}


.three-fourths-vh-sub-menu {
    width: 100vw;
    height: calc(75vh - #{$largeMobileMenuNavBar});

    @media screen and (min-width: $iPad) {
        height: calc(75vh - 500px);
    }

    @media screen and (min-width: 1100px) {
        height: calc(75vh - 80px);
    }

}



/* ------------------------------------------------- 2/3 SCREEN HEIGHT CONTAINERS ---------------------------------------------- */

.two-thirds-vh-sub-menu {
    width: 100vw;
    height: calc(66.66vh - 105px);

    @media screen and (min-width: $iPadPro) {
        height: calc(66.66vh - 163.333px);
    }
}

.two-thirds-height-content-container {
    position: relative;
    height: 66%;
    width: 100%;
}



/* -------------------------------------------------- HALF HEIGHT CONTAINERS ------------------------------------------- */

// Half Screen Height Container
.half-view-height-sub-menu-container {
    position: relative;
    width: 100%;
    height: calc(75vh - 0px);
    
    @media screen and (min-width:$iPad) {
        height: calc(50vh - 0px);
    }

}

// Use this container to take up the upper half of the .full-view-height-sub-menu
.half-height-content-container {
    position: relative;
    width: 100%; 
    height: 50%;
}



/* ------------------------------------------------- 1/3 SCREEN HEIGHT CONTAINERS ---------------------------------------------- */

.third-vh-sub-menu {
    position: relative;
    width: 100vw;
    height: calc(33.33vh - 52.5px);

    @media screen and (min-width: $iPadPro) {
        height: calc(33.33vh - 81.66px);
    }
}

.third-height-content-container {
    position: relative;
    height: 33%;
    width: 100%;
}




/* ------------------------------------------------- HALF SCREEN WIDTH CONTAINERS ---------------------------------------------- */

.mobile-full-desktop-half {
    position: relative;
    width: 100%;
    height: 50%;
    display: inline-block;

    @media screen and (min-width:$iPad) {
        width: 50%;
        height: 100%;
    }
}

.half-width-container {
    position: relative;
    width: 50%;
    height: 100%;
    display: inline-block;
}


/* --------------------------------------------- GRID CONTAINERS --------------------------------------*/

.grid-container {
    display: grid;
    background-color: $MDYellow;
    padding: .6em;
}

.auto-c-3 {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

    @media screen and (min-width:$iPad) {
        grid-template-columns: repeat(3, 1fr);
    }

}

.grid-item {
    background-color: white;
    padding: 10% 2%;
    font-size: 20px;
    text-align: center;

    @media screen and (min-width:$iPad) {
        background-color: white;
        padding: 26% 15%;
        
        text-align: center;
    }
    @media screen and (min-width:$iPadPro) {
        font-size: 30px;
    }
}


/* ----------------------------------------------- IMAGE POSITIONING CONTAINERS ----------------------------------------- */
// Places image inside if you want them off center to the right
.image-box-shift-right-quarter {
    position: absolute;
    width: 100%; 
    height: 100%; 
    min-height: 50%;
    padding-left: 50%;
    
}

.image-box-shift-right-half {
    position: relative;
    width: auto; 
    height: 100%; 
    max-height: 100%;
    padding-left: 50%;
    overflow: hidden;

    img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
    }
}

.image-box-half-height-container {
    background-repeat: no-repeat;
    height: 100%; 
    width: 100%;

    @media screen and (min-width: $iPadPro) and (min-height: 1366px) {
        margin-top: 145px;
    }

    @media screen and (min-width: 1100px) {
        margin-top: 145px;

        img {
            content: url('./images/content/buying-maryland-houses-state-outline.png');
        }
    }

}

.image-box-quarter-height-container {

    background-image: url('./images/content/abandoned-house-cropped-background-mobile.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    height: 100%; 
    width: 100%;

    @media screen and (min-width: $iPadPro) and (min-height: 1366px) {
        background-image: url('./images/content/abandoned-house-cropped-background.png');
    }

    @media screen and (min-width: $iPad) {
        background-image: url('./images/content/abandoned-house-cropped-background-ipad.png');
    }

    @media screen and (min-width: 1100px) {
        margin: 0;
        background-image: url('./images/content/abandoned-house-cropped-background.png');
    }

}

.three-panel-image-box {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
}

.center-image {
    position: relative;
    margin: auto;
    
    border-radius: 10px;
    min-height: 54px;
    min-width: 54px;
}

.center-img {
    position: relative;
    display: block;
    height: 100%;
    margin: auto;
}

.bk-img-home-header {
    background-image: url(./images/banners/abandon-house-header-home-mobile.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/banners/abandon-house-header-home.jpg);
    }
}

.bk-img-down-arrow-overlay {
    background-image: url(./images/content/buying-maryland-houses-1-2-3-home-buying-process-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/buying-maryland-houses-1-2-3-home-buying-process.png);
    }
}

.bk-img-process-1 {
    background-image: url(./images/content/day-home-buying-calendar-icon-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/day-home-buying-calendar-icon-ipad.png);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/day-home-buying-calendar-icon.png);
    }
}

.bk-img-process-2 {
    background-image: url(./images/content/sell-home-quick-cash-deal-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/sell-home-quick-cash-deal-ipad.png);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/sell-home-quick-cash-deal.png);
    }
}

.bk-img-process-3 {
    background-image: url(./images/content/close-home-receive-cash-offer-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/close-home-receive-cash-offer-ipad.png);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/close-home-receive-cash-offer.png);
    }
}

.bk-img-review {
    background-image: url(./images/content/ear-listen-customer-reviews-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/ear-listen-customer-reviews-mobile.png);
    }
}

.bk-img-customer-quotes {
    background-image: url(./images/content/customer-review-quotation-marks-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/customer-review-quotation-marks.png);
    }
}

.bk-img-home-icon {
    background-image: url(./images/logos-icons/home-page-house-icon-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/logos-icons/home-page-house-icon-mobile.png);
    }
}

.bk-img-contact-icon {
    background-image: url(./images/logos-icons/contact-buying-maryland-houses.png);
    background-repeat: no-repeat;
    background-position: center;
}

.bk-img-kitchen-before {
    background-image: url(./images/content/kitchen-before-flip-mobile.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/kitchen-before-flip-ipad.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/kitchen-before-flip.jpg);
    }
}

.bk-img-kitchen-after {
    background-image: url(./images/content/kitchen-after-flip-mobile.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/kitchen-after-flip-ipad.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/kitchen-after-flip.jpg);
    }
}

.bk-img-bathroom-before {
    background-image: url(./images/content/bathroom-before-flip-mobile.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/bathroom-before-flip-ipad.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/bathroom-before-flip.jpg);
    }
}

.bk-img-bathroom-after {
    background-image: url(./images/content/bathroom-after-flip-mobile.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/bathroom-after-flip-ipad.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/bathroom-after-flip.jpg);
    }
}





/* ------------------------------------------------ CLIP-PATHS ---------------------------------------------------------------- */

.arrow-down {
    clip-path: polygon(0% 60%, 20% 60%, 20% 0%, 80% 0%, 80% 60%, 100% 60%, 50% 100%)
}

.arrow-up {
    clip-path: polygon(20% 100%, 80% 100%, 80% 40%, 100% 40%, 50% 0%, 0% 40%, 20% 40%);
}

.arrow-down-around {
    clip-path: polygon(50% 100%, 0% 60%, 30% 60%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 70% 60%, 70% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%); 
    box-shadow: $Topshadow;

    @media screen and (min-width: $iPhone6-7-8) {
        clip-path: polygon(50% 100%, 0% 60%, 20% 60%, 20% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 80% 60%, 80% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%);
    }
}