.nine-sixty-full-height {
    position: absolute;
    width: 100%;
    max-height: calc(100% - $smallMenuNavBar);
    padding: 19%;
}

.center-text-box {
    margin: 0;
    padding: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    @media screen and (min-width:$iPad) {
        width: 55%;
        padding: 0px;
    }

}

#overlay-centered-text-box {
    position: relative;
    margin: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding: 15px 10px;

    @media screen and (min-width:$iPad) {
        width: 70%;
        margin-left: 15%;
        margin-right: 15%;   
    }

    @media screen and (min-width:$iPadPro) {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
    }
}

.overlay-appear-text {
    color: white;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;

    @media screen and (min-width: $iPad) {
        font-size: 20px;
    }
}

.no-show-mobile-text {
    @media screen and (max-width: $iPad) {
        display: none;
    }
}

.one-third-text-box {
    display: inline-block;
    position: relative;
    height: auto; 
    max-width: 33%;
    padding: 1.0em;
    text-align: center;
    vertical-align: middle;
    bottom: 75px;
    border-radius: 30px;
    border: 6px solid rgba(34, 34, 34, 0.8);
}