#bottom-overlay {
    display: none; // Changes to display: flex when scrolling past the toggle point specified within the bottom-overlay script
    flex-flow: row nowrap;
    justify-content: center;

    position: fixed;
    bottom: 0px;
    right: 0px;

    width: 100%;
    padding: 5px 0px;

    background: $gradientBlack;

    z-index: 10;

    button {
        margin: 0px 8px;
        padding: 5px 0px;

        width: 110px;
        height: auto;

        background: linear-gradient(to top, $CoEvoBlue, lighten($CoEvoBlue, 18));

        color: white;
        font-weight: bold;
        font-size: 20px;
        border-radius: 1.0em;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;

        &:active {
            background: linear-gradient(to bottom, $CoEvoBlue, darken($CoEvoBlue, 10));
        }
    }

    @media screen and (max-width: #{$mobileMenuIcon}),
    screen and (max-height: #{$FullMobile}) {
        button {
            margin: 0px 4px;
            font-size: 18px;
        }

    }
}