#logo-wrapper {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 50%;
    z-index: 110;

    #logo {
        
        padding: 8px;

        cursor: pointer;
        overflow: hidden; //Chop off the edges around the logo image so it becomes a circle

        img {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .large {
        background-color: white;

        width: $logoSize;
        height: $logoSize;

        border: $borderStyle;
        border-radius: 30%;
        box-shadow: $circleShadow;
    }

    .small {
        background-color: rgba(255, 255, 255, 0.5);
        width: 70px;
        height: 70px;
        border: $borderStyle;
        border-radius: 20%;
    }

    // At or below 745px wide or 480px tall, 
    // logo size is reduced to half
    @media screen and (max-width: #{$iPad}),
    screen and (max-height: #{$landscapeBP}) {
        
        width: 50%;

        

        .large {
            padding: 0px;
            width: $logoMobile;
            height: $logoMobile;
        }
    }

}