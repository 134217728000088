/// ---------------------- TOP NAVIGATION BAR 


#top-nav {
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 70px;
    z-index: 100;
}

.top-nav-icon-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;

}

.flex-icon-row-box {
    box-sizing: border-box;
    display: flex;
    width: 50%;
    background: $gradientReverse;
    border: 5px solid $CoEvoGreylight;

    @media screen and (min-width: $iPad) {
        
    }
}

.dropdown {
    position: relative;
    display: inline-block;
}



.sell-md-houses {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: $MDY-TerpR-Black;
    color: white;
    font-size: 25px;
    padding: .40em 1.20em;
    border-radius: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    text-align: center;
    text-decoration: none;

    @media screen and (min-width: $iPadPro) {
        font-size: 40px;
        padding: .75em 3em;
    }
}