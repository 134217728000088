.overlay {
    position: absolute;
    top: 0;
	bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.8);
	transition: opacity 200ms;
    display: none;
    opacity: 0;
    z-index: 1000; 
    .cancel {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: default;
    }
    &:target {
    display: block;
    opacity: 1;
    }
}

.popup {
	margin: 45% auto auto auto;
	background: #fff;
	border: 1px solid #666;
	width: auto;
	box-shadow: 0 0 50px rgba(0,0,0,0.5);
    position: relative;
    img {
        width: 100%;
    }
    @media screen and (min-width: $iPad) {
        margin:25% auto auto auto;
    }
    @media screen and (min-width: 1100px) {
        margin:auto auto auto auto;
        height: 100%;
        img {
            max-height: 80%;
            width: 80%;
            margin: 5% 10% 0 10%;
        }
    }
}
.close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    transition: all 200ms;
    font-size: 50px;
    font-weight: bold;
    text-decoration: none;
    color: black;
    &:hover {
      opacity: 1;
    }
    @media screen and (min-width: $iPad) {
        right: 50px;
        font-size: 70px;
    }
    @media screen and (min-width: $iPadPro) {
        right: 80px;
        font-size: 100px;
    }
}