#nav-menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 50%;
    height: 100%;

    @import 'menu_icon';

    #menu-icon {
        display: none;
    }

    #site-links {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%; 
        z-index: 130;

        .siteLink {
            margin: 20px;
            color: black;
            font-size: 18px;
            padding: .5em 1.5em;
            text-align: center;
            font-weight: bold;
            text-decoration: none;
            white-space: nowrap;
            border-radius: 20px;

            cursor: pointer;

            &:hover {
                color: white;
                background: $MDYellow;
                border: 5px solid $TerpRed;
            }
        }

        .big-menu-btn {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 20px;
            background: $MDY-TerpR-Black;
            color: black;
            padding: .75em 3em;
            border-radius: 10px;
            @media screen and (min-width: $iPad) {
                color: white;
            }

            @media screen and (min-width: $iPadPro) {
                border-radius: 40px;
                font-size: 40px;
                color: white;
                padding: .70em 2.25em;
            }
        }
    }

    // At or below 745px wide or 480px tall, 
    // logo decreases so nav menu width needs to be adjusted
    @media screen and(max-width: #{$iPad}),
    screen and (max-height: #{$landscapeBP}) {
        width: 50%;

        #site-links {
            .siteLink {
                margin: 8px;
            }
        }
    }

    // At or below 570px wide, hide the site links and reveal the menu icon button.
    // Site links are revealed via the menu icon button
    @media screen and (max-width: #{$mobileMenuIcon}) {
        position: relative;
        flex-flow: column nowrap;
        
        // justify-content: space-between;

        // overflow: visible;

        #menu-icon {
            display: flex;
        }

        #site-links {
            position: absolute;
            top: $MenuHeightDesktop / 2;

            flex-flow: column nowrap;

            height: 0px;

            overflow: hidden;

            background: rgba(0, 0, 0, .6);
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;

            .siteLink {
                margin: 15px 15px;
                padding: 5px;
                width: 100px;
                border-radius: 30px;
                background: linear-gradient(to bottom, rgba(251, 251, 248, 1.0),rgba(206, 206, 206, 0.8), rgba(251, 251, 248, 1.0));
                box-shadow: $boxShadow;

                &.active {
                    background: $gradientReverse;
                }
            }
        }
    }

    // Smallest width - the 'large' class is not used, everything is toggled
    @media screen and (max-width: $iPhone5) {
        width: 50%;

        

        #site-links {
            top: 70px;
            transform: translateX(calc((100vw - 75px - 20px) / 2 - 50px));
        }
    }
}