.sticky-full-screen {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100%;
    min-height: 100%;
}

.z-layer-1 {
    z-index: 1;
}

.z-layer-2 {
    z-index: 2;
}

.z-layer-3 {
    z-index: 3;
}